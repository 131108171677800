import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { FlatTreeControl } from '@angular/cdk/tree';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  inject,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { groupArrayByKeys } from '@campus/utils';
import { Dictionary } from '@ngrx/entity';
import { NavigationCoreComponent } from '../navigation-core.component';
import { NavigationTreeDataSource } from './navigation-tree-data-source';
import { NavItem } from './navigation-tree-item.interface';

@Component({
  selector: 'campus-navigation-tree',
  templateUrl: './navigation-tree.component.html',
  styleUrls: ['./navigation-tree.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: NavigationTreeComponent,
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavigationTreeComponent
  extends NavigationCoreComponent
  implements OnInit, OnChanges, ControlValueAccessor
{
  private cdRef = inject(ChangeDetectorRef);

  dataSource: NavigationTreeDataSource;
  treeControl: FlatTreeControl<NavItem, number | string>;
  visibleIndexes: Set<number> = new Set();

  dragging = false;
  draggedNodeWasExpanded = false;
  validateDrop = false;
  expandTimeout: ReturnType<typeof setTimeout>;

  expandDelay = 500;

  itemsDict: Dictionary<NavItem> = {};

  @Input() public disabled = false;
  @Input() public items: NavItem[] = [];
  @Input() public trackByFn = this.trackById;
  @Input() public showContextMenu = true;
  @Input() public showDragHandle = true;

  @Output() public moveItem = new EventEmitter();
  @Output() public itemClicked = new EventEmitter();

  public expandedNodes = new Set<string | number>();

  private _value: number = null;

  get value(): number {
    return this._value;
  }

  set value(v: number) {
    this.writeValue(v);
  }

  ngOnInit(): void {
    this.setDataSource(this.items);
    this.setTreeControl();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['items']) {
      this.itemsDict = groupArrayByKeys(this.items, ['id'], null, true);
      this.setDataSource(this.items);
    }
  }

  writeValue(value: number): void {
    this._value = value;
    this.onChange(value);
  }

  onChange = (_) => {};
  onTouched = () => {};

  registerOnChange(fn: (_: any) => void): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }
  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    this.cdRef.markForCheck();
  }

  public hasChildren = (_: number, node: NavItem): boolean => node.hasChildren;

  public shouldRender(node: NavItem): boolean {
    let ancestor = this.getParent(node);
    while (ancestor) {
      if (!this.treeControl.isExpanded(ancestor)) return false;
      ancestor = this.getParent(ancestor);
    }
    return true;
  }

  public setExpanded(node: NavItem, expanded: boolean) {
    if (expanded) {
      this.treeControl.expand(node);
      return;
    }
    this.treeControl.collapse(node);
  }

  /**
   * Handle the drop - here we rearrange the data based on the drop event,
   * then rebuild the tree.
   * */
  public drop(event: CdkDragDrop<string[]>) {
    if (!event.isPointerOverContainer) return;
    this.recalculateVisibleIndexes();

    const dragId = +event.item.data.id;

    const indexDifference = event.currentIndex - event.previousIndex;
    if (indexDifference === 0) return;

    const previousIndex = this.items.find((item) => item.id === dragId).index;
    const previousIndexInSet = Array.from(this.visibleIndexes).indexOf(previousIndex);
    const newIndexInSet = previousIndexInSet + indexDifference;
    const targetIndex = Array.from(this.visibleIndexes)[newIndexInSet];

    const draggedItem = this.itemsDict[dragId];
    const targetItem = this.items[targetIndex];

    const data = {
      draggedItem,
      targetItem,
    };

    this.moveItem.emit(data);
  }

  dragStart(node, event) {
    this.dragging = true;
    if (this.treeControl.isExpanded(node)) {
      this.draggedNodeWasExpanded = true;
      this.treeControl.collapse(node);
    }
  }

  dragEnd(node, event) {
    this.dragging = false;
    if (this.draggedNodeWasExpanded) {
      this.treeControl.expand(node);
    }
    this.draggedNodeWasExpanded = false;
  }

  dragHover(node) {
    if (!this.dragging) return;

    clearTimeout(this.expandTimeout);
    this.expandTimeout = setTimeout(() => {
      this.treeControl.expand(node);
    }, this.expandDelay);
  }

  dragHoverEnd() {
    if (!this.dragging) return;

    clearTimeout(this.expandTimeout);
  }

  rowItemClicked(id: number) {
    this.writeValue(id);
    this.itemClicked.emit(id);
  }

  private getParent(node: NavItem): NavItem | null {
    return this.itemsDict[node.parentId];
  }

  private getContextActions(node: NavItem): any[] {
    return node.contextActions;
  }

  private trackById(index: number, item: NavItem) {
    return item.id;
  }

  private recalculateVisibleIndexes() {
    this.visibleIndexes = new Set();
    this.dataSource.getData().forEach((node) => {
      const shouldRender = this.shouldRender(node);
      if (shouldRender) {
        this.visibleIndexes.add(node.index);
      }
    });
  }

  private setDataSource(data: NavItem[]) {
    this.dataSource = new NavigationTreeDataSource(data, (node) => this.getContextActions(node));
  }

  private setTreeControl() {
    this.treeControl = new FlatTreeControl<NavItem, number | string>(
      (node) => node.depth,
      (node) => this.hasChildren(null, node),
      {
        trackBy: (node) => this.trackByFn(null, node),
      }
    );
  }
}
