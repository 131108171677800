<div class="[ stack stack-space-3xs ]" *ngIf="status$ | async as status">
  <div
    class="input-group [ relative flex w-full items-center ] [ surface ] [ p-b-2xs ] [ border-none ] [ index-surface ]"
    [class]="type === 'search' ? 'gap-2xs' : 'gap-s'"
    [ngClass]="trailingIcon ? ['p-ie-xs', 'p-is-s'] : 'p-i-s'"
  >
    <div
      class="state [ absolute inset-0 ] [ stroke ] [ corner-inherit index-pullback ] [ transition-opacity ]"
      [ngClass]="{
        'bg-opacity-none': !hovered && !focused && !active,
        'bg-opacity-hovered': !active && !focused && hovered,
        'bg-opacity-focused': !active && focused,
        'bg-opacity-pressed': active,
      }"
    ></div>

    <span
      class="input-leading-icon [ on-surface-variant ]"
      [ngClass]="{ 'on-surface icon-opacity-disabled': disabled }"
    >
      <ng-content select="[leading-icon]"></ng-content>
      <campus-icon
        *ngIf="leadingIcon || type === 'search'"
        [svgIcon]="type === 'search' ? 'cds-comp:search' : leadingIcon"
      ></campus-icon>
    </span>

    <div class="input-container [ stack stack-space-3xs ] [ w-full h-full ]">
      <label
        *ngIf="label"
        for="input"
        class="[ label-medium ] [ w-full truncate ]"
        [ngClass]="{
          'text-secondary': (focused || active) && status === 'valid',
          'text-error': status === 'invalid',
          'on-surface text-opacity-full': hovered && !focused && !active && status === 'valid',
          'on-surface-variant text-opacity-high': !hovered && !focused && !active && status === 'valid',
          'on-surface text-opacity-disabled': disabled,
          'cursor-inherit': readOnly
        }"
        data-cy="label-text-field"
      >
        <span class="label-text">{{ label }}</span>
        <span class="required-indicator" *ngIf="required">*</span>
      </label>

      <div class="[ flex items-center ]">
        <div
          class="leading-text [ on-surface-variant ]"
          [ngClass]="{ 'text-opacity-disabled on-surface': disabled }"
          *ngIf="leadingText"
          >{{ leadingText }}</div
        >

        <input
          *ngIf="type !== 'textarea'; else textarea"
          class="[ on-surface w-full ][ body-large ][ text-align-inherit overflow-x-hidden ] [ transparent border-none outline-none ]"
          [class.truncate]="truncate"
          [ngClass]="{ 'text-opacity-disabled': disabled, 'text-right': inputRight, 'cursor-inherit': readOnly }"
          [attr.aria-autocomplete]="ariaAutoComplete"
          [attr.aria-describedby]="
            ariaDescribedBy || supportingText ? 'supporting-' + id + ' trailing-supporting-' + id : undefined
          "
          [attr.aria-expanded]="ariaExpanded"
          [attr.aria-label]="ariaLabel || label"
          [attr.aria-invalid]="status === 'invalid' || undefined"
          [disabled]="disabled"
          [attr.max]="max"
          [attr.maxlength]="maxLength"
          [attr.min]="min"
          [attr.minlength]="minLength"
          [attr.pattern]="pattern"
          [attr.placeholder]="placeholder"
          [attr.readonly]="readOnly"
          [attr.required]="required"
          [attr.step]="step"
          [attr.type]="type === 'search' ? 'text' : type"
          [value]="currentValue ?? ''"
          [attr.tabindex]="readOnly ? -1 : 0"
          [attr.readonly]="readOnly"
          (input)="handleInput($event)"
          (focus)="handleFocusChange()"
          (blur)="handleBlur()"
          #input
          data-cy="input-text-field"
        />

        <ng-template #textarea>
          <textarea
            class="[ on-surface w-full ][ body-large ][ text-align-inherit overflow-x-hidden resize-none ] [ transparent border-none outline-none ]"
            [ngClass]="{ 'text-opacity-disabled': disabled, 'cursor-inherit': readOnly }"
            [attr.aria-describedby]="ariaDescribedBy || supportingText ? 'supporting-' + id : undefined"
            [attr.aria-invalid]="status === 'invalid' || undefined"
            [attr.aria-label]="ariaLabel || label"
            [disabled]="disabled"
            [attr.rows]="rows"
            [attr.maxlength]="maxLength > -1 ? maxLength : undefined"
            [attr.minlength]="minLength > -1 ? minLength : undefined"
            [attr.placeholder]="placeholder"
            [attr.required]="required"
            [attr.tabindex]="readOnly ? -1 : 0"
            (input)="handleInput($event)"
            (focus)="handleFocusChange()"
            (blur)="handleBlur()"
            #input
            data-cy="textarea-text-field"
            >{{ currentValue }}</textarea
          >
        </ng-template>

        <div
          *ngIf="trailingText"
          class="trailing-text [ on-surface-variant ]"
          [ngClass]="{ 'text-opacity-disabled on-surface': disabled }"
          >{{ trailingText }}</div
        >
      </div>
    </div>

    <span
      class="[ relative ] input-trailing-icon [ on-surface-variant ] [ gap-2xs ]"
      [ngClass]="{ 'icon-opacity-disabled on-surface': disabled }"
    >
      <button
        inline-icon-button
        *ngIf="clearable || type === 'search'"
        [class.-m-ie-2xs]="!trailingIcon"
        [ngClass]="!disabled && value ? 'opacity-full' : 'opacity-none'"
        [attr.tabindex]="!disabled && value ? 0 : -1"
        data-cy="clear-button"
        icon="cds-comp:clear"
        class="[ clear-button ][ transition-opacity duration-short-3 ]"
        [class.icon-size-m]="type === 'search'"
        [class.icon-size-s]="clearable"
        [attr.data-keep-closed]="(!disabled && !!value) || undefined"
        (click)="onClear($event)"
      ></button>

      <ng-content select="[trailing-icon]"></ng-content>

      <campus-icon *ngIf="trailingIcon" [svgIcon]="trailingIcon"> </campus-icon>
    </span>

    <div
      class="[ absolute inset-0 ] [ border outline-none corner-inherit ] [ index-content-on-surface pointer-event-none ]"
      [ngClass]="{
      'border-on-surface-variant': !hovered && !focused && !active && status === 'valid',
      'border-on-surface': hovered && !focused && !active && status === 'valid',
      'border-error' : status === 'invalid',
      'border-2': status==='invalid',
      'border-opacity-disabled': disabled,
    }"
    ></div>
    <campus-focus-ring
      *ngIf="element"
      [for]="element"
      inward
      class="[ index-content-on-surface ]"
      [class.text-error]="status === 'invalid'"
    ></campus-focus-ring>
  </div>

  <div
    class="supporting-group [ flex gap-2xs items-center w-full ] [ p-b-none p-i-2xs ]"
    *ngIf="(supportingTextOrErrorText$ | async) || trailingSupportingText"
    data-cy="text-field-error"
  >
    <div
      class="supporting-text [ flex-1 ][ label-small ]"
      [ngClass]="{
        'on-surface-variant' : status === 'valid' && !disabled,
        'text-opacity-disabled on-surface': disabled,
        'text-error': (status === 'invalid' && errorText && !refreshErrorAlert),
      }"
      [attr.id]="supportingText ? 'supporting-' + id : undefined"
      [attr.role]="status === 'invalid' && errorText ? 'alert' : undefined"
      [innerHTML]="supportingTextOrErrorText$ | async"
    >
    </div>
    <div
      *ngIf="trailingSupportingOrCounterText"
      class="trailing-supporting-text [ label-small on-surface-variant align-right ]"
      [ngClass]="{ 'text-opacity-disabled on-surface': disabled }"
      [attr.id]="trailingSupportingOrCounterText ? 'trailing-supporting-' + id : undefined"
    >
      <span [attr.aria-hidden]="isAutoCounter ? true : undefined">{{ trailingSupportingOrCounterText }}</span>
      <span class="visually-hidden" *ngIf="isAutoCounter"
        >Aantal karakters. {{ value?.length }} van {{ maxLength }} ingevoerd.</span
      >
    </div>
  </div>
</div>
