<campus-checkbox
  *ngIf="multiple && hasCheckbox"
  type="checkbox"
  [checked]="selected"
  [disabled]="disabled"
  aria-hidden="true"
  class="pointer-event-none"
  pseudo
></campus-checkbox>

<campus-icon *ngIf="leadingIcon" [svgIcon]="leadingIcon"></campus-icon>

<div class="[ stack stack-space-3xs ][ grow ]">
  <span *ngIf="overline" class="[ label-overline ]">{{ overline }}</span>
  <span *ngIf="label" class="[ body-large ]">{{ label }}</span>
  <span *ngIf="supportingText" class="[ body-small on-surface-variant ]">{{ supportingText }}</span>
</div>

<span *ngIf="trailingSupportingText" class="[ label-small on-surface-variant ]">{{ trailingSupportingText }}</span>

<campus-state-layer [for]="element" class="bg-on-surface"></campus-state-layer>
<campus-focus-ring [for]="element" inward></campus-focus-ring>
